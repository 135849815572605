import React, {useEffect, useState} from "react";
import firebase from "firebase/app";
import "../../App.css";
import axios from "axios";
import CircularController from "../../components/CircularController";
import ProfileImage from "../../components/ProfileImage";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddVisitor from "./AddVisitor";

const PickAttendees = props => {
  const meetingID = props.selectedService.MeetingID;

  const selectedService = props.selectedService;

  const userInfo = props.userData;
  const [family, setFamily] = useState([]);
  const [kidsNumber, setKidsNumber] = useState(0);
  const [kidsZoneMessage, setKidsZoneMessage] = useState("");
  const [bookingService, setBookingService] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState(0);
  const [familyLoading, setFamilyLoading] = useState(false);
  const [showVisitors, setShowVisitors] = useState(false);

  useEffect(() => {
    getServiceInfo();
    getFamily();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPerson = item => {
    console.log(item);

    var tempFamily = family;

    tempFamily.forEach(member => {
      if (item.userid === member.userid) {
        member.checked = !member.checked;
      }
    });

    setSelectedPeople(selectedPeople + 1);
    setTimeout(() => {
      setFamily(tempFamily);
    }, 100);
  };

  const getFamily = () => {
    setFamilyLoading(true);

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        const liveURL = `https://rivers-app-server.herokuapp.com/get-family/?meetingID=${meetingID}&anatomyID=${userInfo.anatomyID}&idToken=${idToken}`;

        axios
          .get(liveURL)
          .then(function (response) {
            setFamily(response.data.data);
            setFamilyLoading(false);
          })
          .catch(err => {
            alert("There has been an error, please try again..");
            console.log(err);
          });
      })
      .catch(function (error) {
        console.log(error);
        alert("There has been an error, please try again..");
      });
  };

  const getServiceInfo = () => {
    firebase
      .firestore()
      .collection("settings")
      .doc("testSettings")
      .get()
      .then(doc => {
        setKidsZoneMessage(doc.data().kidszoneKidsMessage);
      });
  };

  const bookService = () => {
    setBookingService(true);

    var attendingUsers = family.filter(function (item) {
      return item.checked === true;
    });

    if (attendingUsers.length === 0) {
      alert("Please select an attendee before booking a service.");
      setBookingService(false);
      return;
    }

    var attendingUsersId = [];
    var userNames = {};
    attendingUsers.forEach(item => {
      attendingUsersId.push(item.userid);
      userNames[item.userid] = `${item.firstname} ${item.surname}`;
    });

    var userString = attendingUsersId.join("|");
    const action = "add";

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        const liveURL = `https://rivers-app-server.herokuapp.com/book-service/?anatomyID=${userInfo.anatomyID}&userString=${userString}&meetingID=${meetingID}&action=${action}&kidsNumber=${kidsNumber}&idToken=${idToken}`;

        axios
          .get(liveURL)
          .then(function (response) {
            const responseArray = response.data;

            if (responseArray.status === "error") {
              alert(
                "There has been an error booking your service, please try again or contact support."
              );
              setBookingService(false);
            } else if (
              responseArray.data.find(element => element.status === "full")
            ) {
              alert(
                "Unfortunately there is no more space in the service you are trying to book for."
              );
              setBookingService(false);
            } else {
              responseArray.data.forEach(item => {
                if (item.x === 1) {
                  item.madeBy = userInfo.anatomyID;
                  item.firebaseUserID = userInfo.userID;
                  item.meetingTime = selectedService.Date;
                  item.meetingName = selectedService.Meeting;
                  item.formattedDate = selectedService.formattedDate;
                  item.attendeeName = userNames[item.userid];

                  firebase
                    .firestore()
                    .collection("physicalBookings")
                    .doc(
                      `${String(item.bookingid)}_${String(
                        item.meetingid
                      )}_${String(item.userid)}`
                    )
                    .set(item)
                    .then(() => {
                      // Fetch Bookings Here...
                      props.showSnack(true);
                      setBookingService(false);
                      props.setUpdateBookings(true);
                      props.closeModal();
                      console.log("Item Added to DB");
                    });
                }
              });
            }
          })
          .catch(err => {
            alert(
              "There has been an error booking your service, please try again or contact support."
            );
            console.log(err);
          });
      })
      .catch(err => {
        alert(
          "There has been an error booking your service, please try again or contact support."
        );
        console.log(err);
      });
  };

  return (
    <div>
      {!showVisitors ? (
        <div style={{marginBottom: 24, height: "auto"}}>
          <div style={{marginTop: 30}} class="card-heading">
            Book Seats
          </div>
          <div style={{padding: 24}}>
            <div
              style={{
                padding: 12,
                marginBottom: 12,
                borderRadius: 4,
                backgroundColor: "#d2d2d2"
              }}
            >
              <div
                style={{textTransform: "none"}}
                class="book-service-service-name"
              >
                You're booking for{" "}
                {selectedService?.Meeting?.split("(")[0].trim()}.
              </div>
              <div
                style={{textTransform: "none"}}
                class="book-service-service-name"
              >
                {selectedService.Date}
              </div>
              <div
                class="book-service-service-name"
                style={{
                  paddingTop: 20,
                  textTransform: "none"
                }}
              >
                Please note upon check in that standard COVID protocol questions
                will be asked.
              </div>
            </div>
            <div class="card-subheading">Attending church with you:</div>
            {familyLoading ? (
              <div style={{marginVertical: 30}}>
                <CircularController />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                {family.map(item => (
                  <div
                    class="family-list-item"
                    disabled={bookingService || item.eligible !== 1}
                    onClick={() => {
                      if (item.eligible !== 1) {
                        // Do nothing
                      } else {
                        addPerson(item);
                      }
                    }}
                  >
                    <ProfileImage userid={item.userid * 72} />
                    <div style={{flex: 1, marginLeft: 12}}>
                      <div
                        class="book-service-service-name"
                        style={{
                          textTransform: "capitalize",
                          color: item.eligible !== 1 ? "#666" : "#000"
                        }}
                      >
                        {item.firstname} {item.surname}
                      </div>
                      {item.eligible !== 1 && (
                        <div
                          class="book-service-service-name"
                          style={{
                            color: item.eligible !== 1 ? "#666" : "#666",
                            textTransform: "none"
                          }}
                        >
                          {item.eligible_description}
                        </div>
                      )}
                    </div>
                    <div style={{marginRight: 12}}>
                      {item.checked ? (
                        <div class="checked-outer">
                          {/* Checked */}
                          <div class="checked-inner" />
                        </div>
                      ) : item.eligible !== 1 ? (
                        <div
                          class="checked-outer"
                          style={{backgroundColor: "#666"}}
                        >
                          {/* Unchecked */}
                          <div class="unchecked-inner" />
                        </div>
                      ) : (
                        <div class="checked-outer">
                          {/* Unchecked */}
                          <div class="unchecked-inner " />
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                <div
                  class="family-list-item"
                  key={Math.random()}
                  onClick={() => {
                    setShowVisitors(true);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    padding: 10
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      borderBottomColor: "#fff",
                      paddingLeft: 20
                    }}
                  >
                    <div
                      class="book-service-service-name"
                      style={{
                        textTransform: "none"
                      }}
                    >
                      Add Visitors
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottomColor: "#fff",
                      paddingRight: 10
                    }}
                  >
                    <ArrowForwardIosIcon style={{color: "#000"}} />
                  </div>
                </div>

                {/* Kids numbers selector */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <div class="kidszone-list-item">
                    <img
                      style={{width: 50, height: 50, borderRadius: 8}}
                      src="./../Kidszone.png"
                      alt="KZ"
                    />
                    <div style={{flex: 1, paddingLeft: 12}}>
                      <img
                        style={{width: "auto", height: 20, borderRadius: 8}}
                        src="./../KZ_Logo.jpg"
                        alt="KZ"
                      />
                      <div
                        class="book-service-service-name"
                        style={{
                          fontWeight: "normal",
                          color: "#808080",
                          fontSize: 12,
                          textTransform: "none"
                        }}
                      >
                        Select number of children:
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <div
                        onClick={() => {
                          var newKidsNumber = kidsNumber + 1;
                          if (newKidsNumber < 16) {
                            setKidsNumber(newKidsNumber);
                          }
                        }}
                        style={{
                          height: 50,
                          width: 50,
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer"
                        }}
                      >
                        <div style={{fontSize: 16, fontWeight: "bold"}}>+</div>
                      </div>
                      <div class="kidszone-selector">
                        <div
                          style={{textAlign: "center"}}
                          class="book-service-service-name"
                        >
                          {kidsNumber}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          var newKidsNumber = kidsNumber - 1;
                          if (newKidsNumber >= 0) {
                            setKidsNumber(newKidsNumber);
                          }
                        }}
                        style={{
                          height: 50,
                          width: 50,
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer"
                        }}
                      >
                        <div style={{fontSize: 16, fontWeight: "bold"}}>-</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-heading"
                    style={{
                      paddingTop: 12,
                      paddingBottom: 12,
                      fontSize: 14,
                      width: 350
                    }}
                  >
                    {kidsZoneMessage}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <button
                    onClick={() => {
                      bookService();
                    }}
                    class="black-button"
                    style={{marginBottom: 10, marginTop: 10}}
                    disabled={bookingService}
                  >
                    {bookingService ? "Loading..." : "Book Service"}
                  </button>
                  <button
                    disabled={bookingService}
                    onClick={() => {
                      props.closeModal();
                    }}
                    class="black-button"
                    style={{marginBottom: 10, marginTop: 10}}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              paddingTop: 24,
              marginRight: 24,
              marginLeft: 24,
              borderTop: "solid",
              borderTopColor: "#d2d2d2",
              borderTopWidth: 0.5
            }}
          />
        </div>
      ) : (
        <AddVisitor
          onHide={() => {
            setShowVisitors(false);
          }}
          addUserToList={(userID, visitorObject) => {
            var tempFamilyArray = family;

            tempFamilyArray.push(visitorObject);
            setFamily(tempFamilyArray);
            setShowVisitors(false);
          }}
        />
      )}
    </div>
  );
};

export default PickAttendees;
