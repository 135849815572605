import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "../App.css";
import Onboarding from "./onboarding/Onboarding";
import Bookings from "./bookings/Bookings";

import CircularController from "../components/CircularController";

function Home() {
  const [loading, setLoading] = useState(true);
  const [userLinked, setUserLinked] = useState(false);
  const [userData, setUserData] = useState(false);
  // Check if they are admin or not

  useEffect(() => {
    firebase
      .firestore()
      .collection("newAuthAppUsers")
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot((user) => {
        setUserData(user.data());
        if (user.data().anatomyID) {
          setTimeout(() => {
            setUserLinked(true);
            setLoading(false);
          }, 1500);
        } else {
          setUserLinked(false);
          setLoading(false);
        }
      });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <CircularController />
      ) : (
        <div>
          {userLinked ? (
            <Bookings userData={userData} />
          ) : (
            <Onboarding
              userData={userData}
              setUserLinked={(val) => setUserLinked(val)}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Home;
