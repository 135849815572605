import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "../../App.css";
import CircularController from "../../components/CircularController";
import UserList from "./UserList";
import axios from "axios";

function OTP(props) {
  const userData = props.userData;
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [userNotOnList, setUserNotOnList] = useState(false);

  // Check if they are admin or not

  useEffect(() => {
    // Try find the user list.

    getUserList();
  }, []);

  const getUserList = () => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        const liveURL = `https://rivers-app-server.herokuapp.com/find-anatomy-user/?firstName=${userData.firstName}&surname=${userData.surname}&email=${userData.email}&cellNumber=${userData.cellNumber}&idToken=${idToken}`;

        axios
          .get(liveURL)
          .then(function (response) {
            if (
              response.data.status === 200 &&
              response.data?.data?.length > 0
            ) {
              setUserList(response.data.data);
              setTimeout(() => {
                setLoading(false);
              }, 500);
            } else {
              setUserNotOnList(true);
              setUserList([]);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setUserNotOnList(true);
            setUserList([]);
            setLoading(false);
          });
      })
      .catch(function (error) {
        console.log(error);
        // Handle error
        setUserNotOnList(true);
        setUserList([]);
        setLoading(false);
      });
  };

  return (
    <div style={{ width: "100%" }}>
      {!loading ? (
        <div>
          <UserList
            userNotOnList={userNotOnList}
            userData={userData}
            userList={userList}
            setLoading={(val) => {
              console.log(val);
              setLoading(val);
            }}
            setUserLinked={(val) => props.setUserLinked(val)}
            setUserNotOnList={(val) => setUserNotOnList(val)}
          />
        </div>
      ) : (
        <CircularController />
      )}
    </div>
  );
}

export default OTP;
