import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "../../App.css";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ProfileImage from "../../components/ProfileImage";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import FormLabel from "@material-ui/core/FormLabel";

function AddVisitor(props) {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [addingVisitorLoading, setAddingVisitorLoading] = useState("");

  const [visitorLoading, setVisitorLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedUser, setSelectedUSer] = useState([]);
  const [DateOfBirth, setDateOfBirth] = useState("1980-01-01");
  const [gender, setGender] = useState(null);
  const [campus, setCampus] = useState(1);

  // Check if they are admin or not

  useEffect(() => {}, []);

  const resetStates = () => {
    props.onHide();
    setUserList([]);
    setName("");
    setSurname("");
    setEmailAddress("");
    setCellNumber("");
  };

  const createNewUser = () => {
    setAddingVisitorLoading(true);
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        const liveURL = `https://rivers-app-server.herokuapp.com/create-new-anatomy-user/?firstName=${name}&surname=${surname}&email=${emailAddress}&
        mobile=${cellNumber}
          &campus=${campus || 1}
          &gender=${gender || "m"}
          &dateOfBirth=${DateOfBirth || "1980-01-01"}
          &idToken=${idToken}`;

        axios
          .get(liveURL)
          .then(function (response) {
            if (response.data.status === 200) {
              setAddingVisitorLoading(false);
              getUserID(response.data.data.data[0].userid, "Not Found");
            } else {
              alert("There has been an error, please try again.");
            }
          })
          .catch((err) => {
            console.log(err);
            alert("There has been an error, please try again.");
          })
          .catch(function (error) {
            console.log(error);
            alert("There has been an error, please try again.");
          });
      });
  };

  const addUserToList = (userID) => {
    const visitorObject = {
      eligible: 1,
      eligible_description: "We are golden!",
      firstname: name,
      ishead: "False",
      relationship: "Visitor",
      surname: surname,
      userid: userID,
    };
    props.addUserToList(userID, visitorObject);
    resetStates();
  };

  const getUserID = (user, source) => {
    setVisitorLoading(false);
    if (source === "list") {
      addUserToList(user.userid / 72);
    } else if (source === "Not Found") {
      addUserToList(user);
    } else {
      addUserToList(user.data.data[0].userid);
    }
  };

  const addVisitor = () => {
    setVisitorLoading(true);

    // Find the visitor..

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        const liveURL = `https://rivers-app-server.herokuapp.com/find-visitor/?firstName=${name}&surname=${surname}&cell=${cellNumber}&email=${emailAddress}&idToken=${idToken}`;

        axios
          .get(liveURL)
          .then(function (response) {
            if (response.data.status === 200) {
              console.log(response.data);
              setUserList(response.data.data);
              setTimeout(() => {
                setVisitorLoading(false);
              }, 500);
            } else if (response.data.status === 202) {
              getUserID(response.data, "api");
            } else {
              alert("There has been an error, please try again.");
            }
          })
          .catch((err) => {
            alert("There has been an error, please try again.");
            console.log(err);
          });
      })
      .catch(function (error) {
        console.log(error);
        alert("There has been an error, please try again.");
      });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      {userList?.length === 0 && (
        <>
          <div>
            <div class="card-heading">Find Your Visitor</div>
            <div style={{ marginTop: 40 }}>
              <TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                style={{
                  maxWidth: 400,
                  width: 300,
                }}
                id="outlined-basic"
                label="First Name"
                type="text"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: 40 }}>
              <TextField
                onChange={(e) => setSurname(e.target.value)}
                value={surname}
                style={{
                  width: "100%",
                  maxWidth: 400,
                }}
                id="outlined-basic"
                label="Surname"
                type="text"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: 40 }}>
              <TextField
                onChange={(e) => setCellNumber(e.target.value)}
                value={cellNumber}
                style={{
                  width: "100%",
                  maxWidth: 400,
                }}
                id="outlined-basic"
                label="Visitor cell number"
                type="text"
                variant="outlined"
              />
            </div>
            <div
              style={{
                marginTop: 40,
                marginBottom: 40,
                // maxWidth: 400,
                width: "100%",
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <InputLabel
                style={{
                  width: "100%",
                  maxWidth: 400,
                  textAlign: "left",
                  marginBottom: 24,
                }}
                id="demo-simple-select-label"
              >
                Campus
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={campus}
                onChange={(e) => {
                  setCampus(e.target.value);
                }}
                style={{
                  width: "100%",
                  maxWidth: 400,
                  textAlign: "left",
                }}
              >
                <MenuItem value={1}>Sandton</MenuItem>
                <MenuItem value={5}>Kyalami</MenuItem>
                <MenuItem value={2}>Durban North</MenuItem>
                <MenuItem value={4}>Ballito</MenuItem>
                <MenuItem value={7}>Centurion</MenuItem>
              </Select>
            </div>
            <div style={{ marginTop: 40 }}>
              <TextField
                onChange={(e) => setEmailAddress(e.target.value)}
                value={emailAddress}
                style={{
                  width: "100%",
                  maxWidth: 400,
                }}
                id="outlined-basic"
                label="Visitor email address"
                type="text"
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: 40 }}>
              <TextField
                id="datetime-local"
                label="Date of Birth"
                type="date"
                defaultValue={DateOfBirth}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  const date = e.target.value;
                  setDateOfBirth(moment(date).format("YYYY-MM-DD"));
                }}
                style={{
                  width: "100%",
                  maxWidth: 400,
                }}
                variant="outlined"
              />
            </div>
            <div style={{ marginTop: 40, marginBottom: 40 }}>
              <FormControl
                style={{
                  width: "100%",
                  maxWidth: 400,
                }}
                component="fieldset"
              >
                <FormLabel
                  style={{
                    width: "100%",
                    maxWidth: 400,
                    textAlign: "left",
                    marginBottom: 24,
                  }}
                  component="legend"
                >
                  Gender
                </FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <FormControlLabel
                    value="m"
                    control={<Radio color="default" />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="f"
                    control={<Radio color="default" />}
                    label="Female"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div
            style={{
              paddingTop: 24,
            }}
          >
            <button
              onClick={() => {
                addVisitor();
              }}
              class="black-button"
              disabled={
                name === "" ||
                surname === "" ||
                emailAddress === "" ||
                cellNumber === "" ||
                visitorLoading
              }
            >
              {visitorLoading ? "Loading..." : "Add Visitor"}
            </button>
          </div>
          <div
            style={{
              paddingBottom: 60,
              paddingTop: 24,
            }}
          >
            <button
              onClick={() => {
                resetStates();
                props.onHide();
              }}
              class="black-button"
              disabled={visitorLoading}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      {/* List of visitors here... */}
      {userList?.length > 0 && (
        <>
          {userList.map((user) => (
            <div
              class="list-item"
              key={Math.random()}
              onClick={() => {
                setSelectedUSer(user);
                setShowAlert(true);
              }}
            >
              <div style={{ borderBottomColor: "#fff" }}>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 8,
                    backgroundColor: "#fff",
                  }}
                >
                  <ProfileImage userid={user.userid} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  borderBottomColor: "#fff",
                  marginTop: 5,
                  paddingLeft: 20,
                }}
              >
                <div class="list-main-text">
                  {user.fname} {user.sname}
                </div>
              </div>
              <div style={{ borderBottomColor: "#fff", marginTop: 15 }}>
                <ArrowForwardIosIcon style={{ color: "#000" }} />
              </div>
            </div>
          ))}
          <div
            style={{
              paddingTop: 24,
            }}
          >
            <button
              class="black-button"
              onClick={() => {
                createNewUser();
              }}
            >
              {addingVisitorLoading ? "Loading..." : "Visitor Is Not On List"}
            </button>
          </div>
          <div
            style={{
              paddingBottom: 60,
              paddingTop: 24,
            }}
          >
            <button
              onClick={() => {
                resetStates();
              }}
              class="black-button"
            >
              Cancel
            </button>
          </div>
        </>
      )}
      <Dialog
        open={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This person will be added to the list of people you can book for.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "#000" }}
            onClick={() => {
              setShowAlert(false);
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            style={{ color: "#000" }}
            onClick={() => getUserID(selectedUser, "list")}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddVisitor;
