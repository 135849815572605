import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase/app";
import "./assets/fonts/AvenirLTStd-Black.otf";
import "./assets/fonts/AvenirLTStd-Book.otf";
import "./assets/fonts/AvenirLTStd-Roman.otf";

var config = {
  apiKey: "AIzaSyB_ucsf4r7ceeEgJ2KXo-bidaF08b35MRY",
  authDomain: "rivers-app-af0db.firebaseapp.com",
  databaseURL: "https://rivers-app-af0db.firebaseio.com",
  projectId: "rivers-app-af0db",
  storageBucket: "rivers-app-af0db.appspot.com",
  messagingSenderId: "845396143264",
  appId: "1:845396143264:web:bbfec79598ee72c4fe2dc5",
  measurementId: "G-YM69L15Y2E",
};

firebase.initializeApp(config);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
