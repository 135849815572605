import React, { useState } from "react";
import "./App.css";
import firebase from "firebase";
import Loading from "./components/Loading";
import MainRouter from "./components/MainRouter";
import Landing from "./main/Landing";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      setAuthenticated(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      console.log("No user");
      setAuthenticated(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      // No user is signed in.
    }
  });

  return (
    <>
      {loading && <Loading />}
      {!loading && !authenticated && <Landing />}
      {!loading && authenticated && <MainRouter />}
    </>
  );
}

export default App;
