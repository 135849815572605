import React, { useState, useEffect } from "react";
import "firebase/auth";
import "../App.css";

function Loading() {
  useEffect(() => {}, []);

  return (
    <div class="loading-page">
      <div>
        <img class="loading-logo" src="./logo.png" alt="Logo" />
        <div class="h1">Loading...</div>
      </div>
    </div>
  );
}

export default Loading;
