import React, {useEffect, useState} from "react";
import firebase from "firebase/app";
import "../../App.css";
import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";
import CircularController from "../../components/CircularController";
import _ from "lodash";
import ProfileImage from "../../components/ProfileImage";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import axios from "axios";

function ManageBookings(props) {
  const {updateBookings, setUpdateBookings} = props;
  const [loading, setLoading] = useState(true);
  const [userLinked, setUserLinked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [showSnack, setShowSnack] = useState(false);
  const [anatomyID, setAnatomyID] = useState("");
  const [removing, setRemoving] = useState(false);

  useEffect(() => {
    if (!updateBookings) {
      return;
    }
    getBookings(anatomyID);
  }, [updateBookings]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("newAuthAppUsers")
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(user => {
        if (user.data().anatomyID) {
          setAnatomyID(user.data().anatomyID);
          getBookings(user.data().anatomyID);
          setUpdateBookings(false);
          setTimeout(() => {
            setUserLinked(true);
          }, 1500);
        } else {
          setUserLinked(false);
          setLoading(false);
        }
      });
  }, []);

  const getBookings = userID => {
    setLoading(true);

    const url = `https://api.rivers.church/v1/?callback=jsoncallback&apikey=IKswZuLjdLsY9hSnQCvONw==&method=getbookinglist&userid=${userID}`;

    axios
      .get(url)
      .then(function (response) {
        var cleanResponse = response.data.slice(13, response.data.length - 1);
        cleanResponse = JSON.parse(cleanResponse);
        const responseArray = cleanResponse.data;

        if (responseArray) {
          setBookings(responseArray);
        } else {
          setBookings([]);
        }
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      })
      .catch(err => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
        setBookings([]);
        alert("There has been an error, please try again");
        console.log(err);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 60
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          setShowSnack(false);
        }}
        message="Booking removed."
      />
      {loading ? (
        <CircularController />
      ) : (
        <div>
          {userLinked ? (
            <div>
              {bookings.length > 0 ? (
                <div>
                  {bookings.map(item => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 400,
                        height: 80,
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 10
                      }}
                    >
                      <ProfileImage userid={item.AttendeeID} />
                      <div style={{flex: 1, marginLeft: 20}}>
                        <div
                          style={{
                            textTransform: "capitalize",
                            paddingBottom: 5
                          }}
                        >
                          {item.AttendeeName}
                        </div>
                        <div
                          style={{
                            fontWeight: "normal",
                            color: "#808080"
                          }}
                        >
                          {item.MeetingTopic}
                        </div>
                        <div
                          style={{
                            fontWeight: "normal",
                            color: "#808080"
                          }}
                        >
                          {item.meetingTime}
                        </div>
                      </div>
                      <button
                        style={{
                          width: 50,
                          height: 50,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "none",
                          backgroundColor: "#fafafa",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setSelectedBooking(item);
                          setShowAlert(true);
                        }}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <div>There are no bookings to display.</div>
              )}
            </div>
          ) : (
            <div>There are no bookings to display.</div>
          )}
        </div>
      )}
      <Dialog
        open={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will need to make a new booking for this person.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{color: "#000"}}
            onClick={() => {
              setShowAlert(false);
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            style={{color: "#000"}}
            onClick={() => {
              setRemoving(true);
              // Remove Booking, get bookings again.
              const url = `https://api.rivers.church/v1/?callback=jsoncallback&apikey=IKswZuLjdLsY9hSnQCvONw==&method=bookmeeting&userid=${selectedBooking.AttendeeID}&userlist=${selectedBooking.AttendeeID}&meetingid=${selectedBooking.MeetingID}&action=del&kidscount=0`;

              axios
                .get(url)
                .then(function (response) {
                  console.log(response.status, "STATUS");
                  if (response.status === 200) {
                    getBookings(anatomyID);
                    setShowAlert(false);
                    setRemoving(false);
                  } else {
                    alert("There has been an error, please try again");
                  }
                })
                .catch(err => {
                  alert("There has been an error, please try again");
                  console.log(err);
                });
            }}
            color="primary"
            autoFocus
          >
            {removing ? "Loading..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManageBookings;
