import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "../App.css";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

function ProfileImage(props) {
  const userid = props.userid;
  const [showPlaceHolder, setShowPlaceHolder] = useState(false);

  // Check if they are admin or not

  return (
    <div>
      {showPlaceHolder ? (
        <img
          style={{ width: 50, height: 50, borderRadius: 8 }}
          src="https://members.riverschurch.org.za/anatomy5/data/user.jpg"
          alt="Profile Pic"
          onError={() => {
            console.log("Error");
          }}
        />
      ) : (
        <img
          style={{ width: 50, height: 50, borderRadius: 8 }}
          src={`https://members.riverschurch.org.za/anatomy5/data/user${
            userid || ""
          }.jpg`}
          alt="Profile Pic"
          onError={() => {
            setShowPlaceHolder(true);
          }}
        />
      )}
    </div>
  );
}

export default ProfileImage;
