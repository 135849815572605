import React, { useState } from "react";
import firebase from "firebase/app";
import "../../App.css";
import axios from "axios";
import moment from "moment";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ProfileImage from "../../components/ProfileImage";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

function UserList(props) {
  const userData = props.userData;
  const [showDialog, setShowDialog] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [OTP, setOTP] = useState("");
  const [selectedUser, setSelectedUser] = useState([]);
  const [mobileLoading, setMobileLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [generatedOTP, setGeneratedOTP] = useState("");
  const [loading, setLoading] = useState(false);

  const createNewUser = () => {
    props.setLoading(true);

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        const liveURL = `https://rivers-app-server.herokuapp.com/create-new-anatomy-user/?firstName=${
          userData.firstName
        }&surname=${userData.surname}&email=${userData.email}&mobile=${
          userData.cellNumber
        }
        &campus=${userData.campus || 1}&gender=${
          userData.gender || "m"
        }&dateOfBirth=${
          userData.dateOfBirth || "1980-01-01"
        }&idToken=${idToken}`;
        axios
          .get(liveURL)
          .then(function (response) {
            console.log("response", response.data.data.data[0].userid);
            if (response.data.status === 200) {
              firebase
                .firestore()
                .collection("newAuthAppUsers")
                .doc(firebase.auth().currentUser.uid)
                .update({
                  anatomyID: response.data.data.data[0].userid,
                })
                .then(() => {
                  props.setLoading(false);
                  props.setUserLinked(true);
                });
            } else {
              alert(
                "Error",
                "There has been an error. Please email app@river.church for support."
              );
              props.setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            alert(
              "Error",
              "There has been an error. Please email app@river.church for support."
            );
            props.setLoading(false);
          });
      })
      .catch(function (error) {
        console.log(error);
        alert(
          "Error",
          "There has been an error. Please email app@river.church for support."
        );
        props.setLoading(false);
      });
  };

  const sendOTPMobile = () => {
    setMobileLoading(true);

    var OTPNumber = Math.floor(1000 + Math.random() * 9000);

    setGeneratedOTP(OTPNumber);

    var sendOTPFunction = firebase.functions().httpsCallable("sendOTP_BulkSMS");

    sendOTPFunction({
      userid: selectedUser.userid,
      OTP: OTPNumber,
    })
      .then(function (result) {
        const resultObject = result;
        return resultObject;
      })
      .then((resultObject) => {
        setMobileLoading(false);
        setCodeSent(true);
      })
      .catch((err) => {
        setMobileLoading(false);
        alert(
          "There has been an error. Please try again or try a different method."
        );
      });
  };

  const sendOTPEmail = () => {
    setEmailLoading(true);

    var OTPNumber = Math.floor(1000 + Math.random() * 9000);

    setGeneratedOTP(OTPNumber);

    var sendEmailOTPFunction = firebase
      .functions()
      .httpsCallable("sendEmailOTP");

    sendEmailOTPFunction({
      userid: selectedUser.userid,
      OTP: OTPNumber,
    })
      .then(function (resultObject) {
        setEmailLoading(false);
        // Send to next page.
        setCodeSent(true);
      })
      .catch((err) => {
        setEmailLoading(false);
        alert(
          "There has been an error. Please try again or try a different method."
        );
      });
  };

  const validateOTP = () => {
    if (parseInt(generatedOTP) === parseInt(OTP)) {
      setLoading(true);

      firebase
        .firestore()
        .collection("newAuthAppUsers")
        .doc(firebase.auth().currentUser.uid)
        .update({
          anatomyID: selectedUser.userid / 72,
          campus: selectedUser.CampusID || 1,
        })
        .then(() => {
          setTimeout(() => {
            props.setUserLinked(true);
            setLoading(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert("The OTP You entered was incorrect, please try again.");
        });
    } else {
      setLoading(false);
      alert("The OTP You entered was incorrect, please try again.");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div>
        {props.userNotOnList ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: 60 }}>
              <div class="card-heading">Hi there!</div>
            </div>
            <div style={{ marginTop: 12, marginBottom: 32 }}>
              <div style={{ marginTop: 20 }} class="card-subheading">
                Hey {userData.firstName}, we don't seem to have a profile that
                matches your credentials.
              </div>
            </div>
            <div>
              <button
                style={{ padding: 5, height: "auto" }}
                onClick={() => {
                  createNewUser();
                }}
                class="black-button"
              >
                CONTINUE WITH NEW ACCOUNT
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: 60 }}>
              <div class="card-heading">Oh, it's you. Hello!</div>
            </div>
            <div
              class="card-subheading"
              style={{ marginTop: 12, marginBottom: 32 }}
            >
              <div>
                Hey {userData.firstName}, we seem to have a profile that matches
                what you’ve entered. <br />
                <br />
                To avoid having duplicate profiles, select yours from the list
                below.
              </div>
            </div>
            <div>
              {props.userList &&
                props.userList.map((user) => (
                  <div
                    class="list-item"
                    key={Math.random()}
                    onClick={() => {
                      var days = user.dob / 72;
                      const age = moment("1899/12/31")
                        .add(days, "days")
                        .format("YYYY/MM/DD");
                      var years = moment().diff(age, "years");
                      if (years < 13 && years > 1) {
                        alert(
                          "Sorry, only people over the age of 12 can register on this app."
                        );
                      } else {
                        setSelectedUser(user);
                        setShowDialog(true);
                      }
                    }}
                  >
                    <div style={{ borderBottomColor: "#fff" }}>
                      <div
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 8,
                          backgroundColor: "#fff",
                        }}
                      >
                        <ProfileImage userid={user.userid} />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        borderBottomColor: "#fff",
                        marginTop: 5,
                        paddingLeft: 20,
                      }}
                    >
                      <div class="list-main-text">
                        {user.fname} {user.sname}
                      </div>
                    </div>
                    <div style={{ borderBottomColor: "#fff", marginTop: 15 }}>
                      <ArrowForwardIosIcon style={{ color: "#000" }} />
                    </div>
                  </div>
                ))}
            </div>
            <div style={{ marginTop: 30 }}>
              <button
                onClick={() => {
                  props.setUserNotOnList(true);
                }}
                class="black-button"
              >
                MY NAME IS NOT HERE
              </button>
            </div>
          </div>
        )}
        <Dialog
          open={showDialog}
          onClose={() => {
            setShowDialog(false);
          }}
          fullWidth
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <div class="card-heading">Almost Done</div>
            </DialogTitle>
            {!codeSent ? (
              <DialogContent>
                <div
                  style={{ marginBottom: 20 }}
                  class="card-subheading"
                  id="alert-dialog-description"
                >
                  To make sure that it’s you {props.userData.firstName}, we need
                  to send you a 4-digit verification code.
                </div>
                <hr
                  style={{
                    marginLeft: "20%",
                    marginRight: "20%",
                    marginBottom: 20,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    marginBottom: 10,
                  }}
                >
                  <div class="list-main-text">
                    Sending to mobile will send an SMS to {selectedUser.mobile}.
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <button
                    disabled={emailLoading || mobileLoading}
                    onClick={() => {
                      sendOTPMobile();
                    }}
                    class="black-button"
                    style={{ marginBottom: 20 }}
                  >
                    {mobileLoading ? "Loading..." : "Send To Mobile"}
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    marginBottom: 10,
                  }}
                >
                  <div class="list-main-text">
                    Sending to email will send an email to {selectedUser.email}.
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    marginBottom: 10,
                  }}
                >
                  <button
                    disabled={emailLoading || mobileLoading}
                    onClick={() => {
                      sendOTPEmail();
                    }}
                    class="black-button"
                    style={{ marginBottom: 20 }}
                  >
                    {emailLoading ? "Loading..." : "Send To Email"}
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    marginBottom: 10,
                  }}
                >
                  <button
                    disabled={emailLoading || mobileLoading}
                    onClick={() => {
                      setShowDialog(false);
                    }}
                    class="black-button"
                    style={{ marginBottom: 10 }}
                  >
                    Cancel
                  </button>
                </div>
              </DialogContent>
            ) : (
              <DialogContent>
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                  <TextField
                    onChange={(e) => setOTP(e.target.value)}
                    value={OTP}
                    style={{
                      width: "100%",
                      maxWidth: 400,
                    }}
                    id="outlined-basic"
                    label="Enter your 4 digit OTP here."
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <button
                    disabled={loading}
                    onClick={() => {
                      validateOTP();
                    }}
                    class="black-button"
                    style={{ marginBottom: 10 }}
                  >
                    {loading ? "Loading..." : "Verify Code"}
                  </button>
                  <button
                    onClick={() => {
                      setShowDialog(false);
                      setCodeSent(false);
                    }}
                    class="black-button"
                    style={{ marginBottom: 10 }}
                  >
                    Cancel
                  </button>
                </div>
              </DialogContent>
            )}
          </div>
        </Dialog>
      </div>
    </div>
  );
}

export default UserList;
