import React from "react";
import "../App.css";
import { CircularProgress } from "@material-ui/core";

function CircularController() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress style={{ color: "#000" }} />
    </div>
  );
}

export default CircularController;
