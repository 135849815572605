import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "../App.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Login from "../components/Login";
import Register from "../components/Register";

function Landing() {
  const [accountState, setAccountState] = useState("login");

  return (
    <div class="landing-page">
      {accountState === "login" ? (
        <Login setAccountState={setAccountState} />
      ) : (
        <Register setAccountState={setAccountState} />
      )}
    </div>
  );
}

export default Landing;
