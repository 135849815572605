import React, {useEffect, useState} from "react";
import firebase from "firebase/app";
import "../../App.css";
import {withStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import CircularController from "../../components/CircularController";
import PickAttendees from "./PickAttendees";
import Snackbar from "@material-ui/core/Snackbar";
import ManageBookings from "./ManageBookings";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

function Bookings(props) {
  const [campus, setCampus] = useState("Sandton");
  const [loadingServices, setLoadingServices] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [pickAttendees, setPickAttendees] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [anatomyServices, setAnatomyServices] = useState([]);
  const [firebaseServices, setFirebaseServices] = useState([]);
  const [updateBookings, setUpdateBookings] = useState(false);

  const userData = props.userData;

  const BootstrapInput = withStyles(theme => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: "Avenir Roman",
      width: 300,
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      }
    }
  }))(InputBase);

  useEffect(() => {
    getServiceList();
    getAnatomyServices();
  }, []);

  const getServiceList = () => {
    firebase
      .firestore()
      .collection("availableServices")
      .onSnapshot(doc => {
        var meetings = [];

        if (doc) {
          doc.docs.forEach(item => {
            meetings.push(item.data());
          });
          setFirebaseServices([]);
          setFirebaseServices(meetings);
        } else {
          setServiceList([]);
        }
      });
  };

  const getAnatomyServices = () => {
    const url = `https://api.rivers.church/v1/?callback=jsoncallback&apikey=IKswZuLjdLsY9hSnQCvONw==&method=meetingdisplay&userid=${userData.anatomyID}`;

    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        var cleanResponse = response.data.slice(13, response.data.length - 1);
        cleanResponse = JSON.parse(cleanResponse);
        const responseArray = cleanResponse.data;

        var meetingIDArray = [];

        responseArray.forEach(item => {
          meetingIDArray.push(item.MeetingID);
        });

        setAnatomyServices(meetingIDArray);
        setLoadingServices(false);
      })
      .catch(err => {
        setAnatomyServices([]);
        alert("There has been an error, please try again or contact support.");
      });
  };

  useEffect(() => {
    if (firebaseServices.length === 0 || anatomyServices.length === 0) {
      setServiceList([]);
      return;
    }

    var tempServiceArray = [];

    firebaseServices.forEach(item => {
      const meetingExists = anatomyServices.find(
        element => element === item.MeetingID
      );
      if (meetingExists) {
        var formattedDate = moment(item.Date_127).add(2, "hours").format("X");
        const meeting = item;

        meeting.formattedDate = parseInt(formattedDate);

        tempServiceArray.push(meeting);
      }
    });

    const sortedByDate = _.sortBy(tempServiceArray, "formattedDate");

    setServiceList(sortedByDate);
    setLoadingServices(false);
  }, [anatomyServices, firebaseServices]);

  const handleCampusChange = event => {
    setCampus(event.target.value);
    getServiceList(event.target.value);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={showSnack}
        autoHideDuration={6000}
        onClose={() => {
          setShowSnack(false);
        }}
        message=" Success! Booking complete"
      />
      <div style={{marginTop: 30, marginBottom: 30}}>
        <div class="card-heading">Book Your Service Seats</div>
        <div style={{marginTop: 15}} class="card-subheading">
          To join us in person, please pick from one of the available services
          below.
        </div>
      </div>
      <FormControl>
        <InputLabel htmlFor="demo-customized-select-native">
          Select Campus
        </InputLabel>
        <NativeSelect
          id="demo-customized-select-native"
          value={campus}
          onChange={handleCampusChange}
          input={<BootstrapInput />}
        >
          <option value={"Sandton"}>Sandton</option>
          <option value={"Kyalami"}>Kyalami</option>
          <option value={"Centurion"}>Centurion</option>
          <option value={"Durban"}>Durban North</option>
          <option value={"Ballito"}>Ballito</option>
        </NativeSelect>
      </FormControl>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          width: 350
        }}
      >
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            textAlign: "left",
            justifyContent: "flex-start",
            flex: 1
          }}
          class="card-subheading"
        >
          Available Services
        </div>
        <div
          style={{
            fontSize: 14,
            textAlign: "left",
            justifyContent: "flex-start"
          }}
          class="card-subheading"
        >
          Seats Available
        </div>
      </div>
      <div style={{marginTop: 30}}>
        {loadingServices ? (
          <CircularController />
        ) : (
          <div>
            {serviceList.filter(item => item.Campus === campus).length > 0 ? (
              <div>
                {serviceList
                  .filter(item => item.Campus === campus)
                  .map(item => (
                    <div
                      onClick={() => {
                        if (item.Available > 0) {
                          setSelectedService(item);
                          setPickAttendees(true);
                        }
                      }}
                      disabled={item.Available <= 0}
                      style={{
                        height: 70,
                        marginTop: 12,
                        marginBottom: 12,
                        display: "flex",
                        flexDirection: "row",
                        width: 350,
                        cursor: item.Available > 0 ? "pointer" : "cursor",
                        alignItems: "center",
                        padding: 5,
                        borderRadius: 5
                      }}
                      class="service-list-item"
                    >
                      <div style={{flex: 1}}>
                        <div
                          class="book-service-service-name"
                          style={{
                            color: item.Available > 0 ? "#000" : "#808080"
                          }}
                        >
                          {item.Meeting.split("(")[0]}
                        </div>
                        <div
                          class="book-service-service-name"
                          style={{
                            fontWeight: "normal",
                            color: item.Available > 0 ? "#000" : "#808080",
                            paddingTop: 6
                          }}
                        >
                          {item.Date}
                        </div>
                      </div>
                      <div
                        style={{
                          width: 60,
                          height: 30,
                          borderRadius: 4,
                          backgroundColor: item.Available
                            ? "#417505"
                            : "#808080",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          class="book-service-service-name"
                          style={{color: "#ffffff"}}
                        >
                          {item.Available === 0 ? "Full" : item.Available}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div class="card-content-text">
                There are currently no services available to book for{" "}
                {_.startCase(campus.toLowerCase())}.
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog
        open={pickAttendees}
        onClose={() => {
          setPickAttendees(false);
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PickAttendees
          showSnack={val => setShowSnack(val)}
          meetingID={selectedService.meetingID}
          selectedService={selectedService}
          userData={userData}
          closeModal={() => {
            setPickAttendees(false);
          }}
          setUpdateBookings={val => {
            setUpdateBookings(true);
          }}
        />
      </Dialog>
      <div style={{marginTop: 45, marginBottom: 30}}>
        <div class="card-heading">Manage Your Bookings</div>
        <div style={{marginTop: 15}} class="card-subheading">
          If you have any services booked, manage them below.
        </div>
      </div>
      <ManageBookings
        updateBookings={updateBookings}
        setUpdateBookings={val => {
          setUpdateBookings(val);
        }}
      />
    </div>
  );
}

export default Bookings;
