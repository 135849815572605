import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import firebase from "firebase/app";
// Page Imports
import Home from "../main/Home";
// Component Imports
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { List, Typography, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ManageBookings from "../main/bookings/ManageBookings";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#000000",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#000000",
    width: drawerWidth,
    color: "#ffffff",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
}));

export default function MainApp(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [pageName, setPageName] = useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      setPageName("Home");
    } else {
      setPageName("Rivers Church");
    }
  }, []);

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              style={{ flex: 1 }}
              class="page-header"
              variant="h6"
              noWrap
            >
              {pageName}
            </Typography>
            <div>Rivers Church</div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div style={{ display: "flex" }} className={classes.drawerHeader}>
            <div
              style={{
                flex: 1,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img class="drawer-logo" src="./logo.png" alt="Logo" />
            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon style={{ color: "#ffffff" }} />
              ) : (
                <ChevronRightIcon style={{ color: "#ffffff" }} />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem
              onClick={() => {
                handleDrawerClose();
                setPageName("Home");
              }}
              component={Link}
              to="/"
              button
            >
              <ListItemIcon>
                <DashboardIcon style={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Typography class="menu-font">Home</Typography>
            </ListItem>

            <ListItem
              onClick={() => {
                handleDrawerClose();
                firebase.auth().signOut();
              }}
              component={Link}
              to={`/`}
              button
            >
              <ListItemIcon>
                <SettingsIcon style={{ color: "#ffffff" }} />
              </ListItemIcon>
              <Typography class="menu-font">Sign Out</Typography>
            </ListItem>
          </List>
          <Divider />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/my-bookings">
              <ManageBookings />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
}

function HomePage() {
  return <Home />;
}
