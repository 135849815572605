import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "../App.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Login(props) {
  const classes = useStyles();
  const [loggingIn, setLoggingIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [emailForReset, setEmailForReset] = useState("");

  const logIn = () => {
    setLoggingIn(true);
    setErrorText("");

    firebase
      .auth()
      .signInWithEmailAndPassword(email.trim(), password)
      .then((user) => {
        setLoggingIn(false);
      })
      .catch(function (error) {
        setErrorText(error.message);
        setLoggingIn(false);
      });
  };

  return (
    <div class="landing-page">
      <nav
        style={{
          height: 60,
          display: "flex",
          flexDirection: "row",
          padding: 15,
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>
          <img class="header-logo" src="./logo.png" alt="Logo" />
        </div>
      </nav>
      <div className={classes.root}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 60,
          }}
          spacing={3}
        >
          <Grid item xs={12} lg={6}>
            <Paper style={{ margin: 30 }} className={classes.paper}>
              <div class="card-heading">
                Log In to the Rivers Church Booking system
              </div>
              <hr style={{ width: "80%", color: "#666666" }} />
              <div style={{ marginTop: 20 }} class="card-subheading">
                <br />
                If you have login details for the Rivers App, use those here.
              </div>
              <div
                style={{ marginTop: 20, marginBottom: 20 }}
                class="error-text"
              >
                {errorText}
              </div>
              <div>
                <div style={{ marginTop: 40 }}>
                  <TextField
                    value={email}
                    style={{
                      width: "100%",
                      maxWidth: 400,
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    type="Text"
                  />
                </div>
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                  <TextField
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    style={{
                      width: "100%",
                      maxWidth: 400,
                    }}
                    id="outlined-basic"
                    label="Password"
                    type="password"
                    variant="outlined"
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button
                    disabled={loggingIn}
                    onClick={() => {
                      logIn();
                    }}
                    class="black-button"
                  >
                    {loggingIn ? "Loading..." : "Log In"}
                  </button>

                  <button
                    style={{ marginTop: 30 }}
                    disabled={loggingIn}
                    onClick={() => {
                      props.setAccountState("register");
                    }}
                    class="black-button"
                  >
                    Create New Account
                  </button>
                  <button
                    style={{ marginTop: 30 }}
                    disabled={loggingIn}
                    onClick={() => {
                      setShowPasswordReset(true);
                    }}
                    class="black-button"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={showPasswordReset}
        onClose={() => {
          setShowPasswordReset(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reset Password"}</DialogTitle>
        <DialogContent>
          {!resetSuccess ? (
            <div>
              <DialogContentText id="alert-dialog-description">
                Please enter your email address below.
              </DialogContentText>{" "}
              <div style={{ marginTop: 40, marginBottom: 40 }}>
                <TextField
                  onChange={(e) => setEmailForReset(e.target.value)}
                  value={emailForReset}
                  style={{
                    width: 350,
                    maxWidth: 400,
                  }}
                  id="outlined-basic"
                  label="Email Address"
                  type="text"
                  variant="outlined"
                />
              </div>
            </div>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Please check your email for further instructions.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "#000" }}
            onClick={() => {
              setShowPasswordReset(false);
              setResetSuccess(false);
            }}
            disabled={resetLoading}
            color="primary"
          >
            Cancel
          </Button>
          {!resetSuccess ? (
            <Button
              disabled={
                resetLoading || resetSuccess || !emailForReset.includes("@")
              }
              style={{ color: "#000" }}
              onClick={() => {
                setResetLoading(true);
                firebase
                  .auth()
                  .sendPasswordResetEmail(emailForReset)
                  .then(() => {
                    setResetLoading(false);
                    setResetSuccess(true);
                  })
                  .catch((err) => {
                    setResetLoading(false);
                    alert(
                      "There has been an error, please try again and make sure your email address is correct."
                    );
                    console.log(err);
                  });
              }}
              color="primary"
              autoFocus
            >
              {resetLoading ? "Loading..." : "Reset Password"}
            </Button>
          ) : (
            <Button
              style={{ color: "#000" }}
              onClick={() => {
                setShowPasswordReset(false);
                setResetSuccess(false);
              }}
              color="primary"
              autoFocus
            >
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Login;
