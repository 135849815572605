import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "../App.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Register(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [campus, setCampus] = useState(1);
  const [gender, setGender] = useState(null);
  const [DateOfBirth, setDateOfBirth] = useState("1980-01-01");

  const [errorText, setErrorText] = useState("");

  useEffect(() => {}, []);

  const registerUser = () => {
    setErrorText("");
    setLoading(true);

    if (
      name === "" ||
      surname === "" ||
      !email.includes("@") ||
      cellNumber.length < 8
    ) {
      setErrorText("Please make sure all the fields are entered correctly");
      setLoading(false);
    } else {
      setErrorText("");
      firebase
        .auth()
        .createUserWithEmailAndPassword(email.trim(), password)
        .then((user) => {
          const uid = user.user.uid;

          firebase
            .firestore()
            .collection("newAuthAppUsers")
            .doc(uid)
            .set({
              email: email,
              userID: uid,
              firstName: name,
              surname: surname,
              cellNumber: cellNumber,
              gender: gender,
              campus: campus,
              dateOfBirth: DateOfBirth,
            })
            .then(() => {
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setErrorText(
                "There has been an error, please try again or contact support at app@rivers.church"
              );
            });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          const errorMessage = err.message;
          if (errorMessage) {
            setErrorText(errorMessage);
          } else {
            setErrorText(
              "There has been an error, please try again or contact support at app@rivers.church"
            );
          }
        });
    }
  };

  return (
    <div class="landing-page">
      <nav
        style={{
          height: 60,
          display: "flex",
          flexDirection: "row",
          padding: 15,
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>
          <img class="header-logo" src="./logo.png" alt="Logo" />
        </div>
      </nav>
      <div className={classes.root}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 60,
          }}
          spacing={3}
        >
          <Grid item xs={12} lg={6}>
            <Paper style={{ margin: 30 }} className={classes.paper}>
              <div class="card-heading">Create a New Account.</div>
              <hr style={{ width: "80%", color: "#666666" }} />
              <div style={{ marginTop: 20 }} class="card-subheading">
                <br />
                This account can also be used for the Rivers App.
              </div>
              <div
                style={{ marginTop: 20, marginBottom: 20 }}
                className="error-text"
              >
                {errorText}
              </div>
              <div>
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                  <TextField
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    style={{
                      width: "100%",
                      maxWidth: 400,
                    }}
                    id="outlined-basic"
                    label="First Name"
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                  <TextField
                    onChange={(e) => setSurname(e.target.value)}
                    value={surname}
                    style={{
                      width: "100%",
                      maxWidth: 400,
                    }}
                    id="outlined-basic"
                    label="Surname"
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div style={{ marginTop: 40 }}>
                  <TextField
                    value={email}
                    style={{
                      width: "100%",
                      maxWidth: 400,
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    type="text"
                  />
                </div>
                <div style={{ marginTop: 40 }}>
                  <TextField
                    value={cellNumber}
                    style={{
                      width: "100%",
                      maxWidth: 400,
                    }}
                    onChange={(e) => setCellNumber(e.target.value)}
                    id="outlined-basic"
                    label="Cell Number"
                    variant="outlined"
                    type="text"
                  />
                </div>
                <div
                  style={{
                    marginTop: 40,
                    marginBottom: 40,
                    // maxWidth: 400,
                    width: "100%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <InputLabel
                    style={{
                      width: "100%",
                      maxWidth: 400,
                      textAlign: "left",
                      marginBottom: 24,
                    }}
                    id="demo-simple-select-label"
                  >
                    Campus
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={campus}
                    onChange={(e) => {
                      setCampus(e.target.value);
                    }}
                    style={{
                      width: "100%",
                      maxWidth: 400,
                      textAlign: "left",
                    }}
                  >
                    <MenuItem value={1}>Sandton</MenuItem>
                    <MenuItem value={5}>Kyalami</MenuItem>
                    <MenuItem value={2}>Durban North</MenuItem>
                    <MenuItem value={4}>Ballito</MenuItem>
                    <MenuItem value={7}>Centurion</MenuItem>
                  </Select>
                </div>
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                  <FormControl
                    style={{
                      width: "100%",
                      maxWidth: 400,
                    }}
                    component="fieldset"
                  >
                    <FormLabel
                      style={{
                        width: "100%",
                        maxWidth: 400,
                        textAlign: "left",
                        marginBottom: 24,
                      }}
                      component="legend"
                    >
                      Gender
                    </FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <FormControlLabel
                        value="m"
                        control={<Radio color="default" />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="f"
                        control={<Radio color="default" />}
                        label="Female"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <TextField
                  id="datetime-local"
                  label="Date of Birth"
                  type="date"
                  defaultValue={DateOfBirth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    const date = e.target.value;
                    setDateOfBirth(moment(date).format("YYYY-MM-DD"));
                  }}
                  style={{
                    width: "100%",
                    maxWidth: 400,
                  }}
                  variant="outlined"
                />
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                  <TextField
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    style={{
                      width: "100%",
                      maxWidth: 400,
                    }}
                    id="outlined-basic"
                    label="Password"
                    type="password"
                    variant="outlined"
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button
                    disabled={loading}
                    onClick={() => {
                      registerUser();
                    }}
                    class="black-button"
                  >
                    {loading ? "Loading..." : "Sign Up"}
                  </button>
                </div>
                <button
                  style={{ marginTop: 30 }}
                  disabled={loading}
                  onClick={() => {
                    props.setAccountState("login");
                  }}
                  class="black-button"
                >
                  I Have An Account
                </button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Register;
